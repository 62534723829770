import React, { useState } from "react"
import { withPrefix } from "gatsby"

import * as styles from "./TheLoopHeading.module.css"

const TheLoopHeading: React.FC = () => {
  const [response, setResponse] = useState<any>()
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false)

  const onSignupHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const body = {
      email: formData.get("email"),
    }

    setAwaitingResponse(true)
    try {
      const res = await fetch("/api/signup", {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      })
      const jsonResponse = await res.json()
      setResponse(jsonResponse)
    } finally {
      setAwaitingResponse(false)
    }
  }

  return (
    <>
      <div className={styles.headingContainer}>
        <h1>The Loop</h1>
        <div className={styles.signupContainer}>
          <div className={styles.heading}>Stay in The Loop</div>
          <p>
            Subscribe to stay updated on the most important tech updates in your
            industry.
          </p>
          <form onSubmit={onSignupHandler}>
            <input
              name="email"
              aria-label="email address"
              placeholder="Enter email"
            />
            {response?.ok ? (
              <div className={styles.signupSuccess}>Signup successful.</div>
            ) : (
              <button type="submit" disabled={awaitingResponse}>SIGN UP</button>
            )}
          </form>
        </div>
      </div>
      <img
        alt="arrow line"
        className={styles.arrowLine}
        src={withPrefix("/images/line_arrow_b.svg")}
        id="theLoopArrowLine"
      />
    </>
  )
}

export default TheLoopHeading
