import React from "react"

import TheLoopHeading from "econify/routes/TheLoop/TheLoopHeading"
import * as styles from "./TheLoopDetail.module.css"

export interface Broadcast {
  id: string
  content: string
  publishedAt: string
}

interface TheLoopDetailProps {
  broadcast: Broadcast
}
const TheLoopDetail: React.FC<TheLoopDetailProps> = ({ broadcast }) => (
  <>
    <div className={styles.container}>
      <TheLoopHeading />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: broadcast?.content }}
      />
      {broadcast?.publishedAt && <div>Published on {new Date(broadcast.publishedAt).toDateString()}</div>}
    </div>
  </>
)

export default TheLoopDetail
