import React from "react"
import { graphql } from "gatsby"

import Header from "econify/components/Header"
import TheLoopDetail from "econify/routes/TheLoop/Detail"
import { Broadcast } from "econify/routes/TheLoop/Detail/TheLoopDetail"

type TheLoopDetailPageProps = {
  data: {
    convertKitBroadcast: Broadcast
  }
}

const TheLoopDetailPage: React.FC<TheLoopDetailPageProps> = ({ data }) => {
  const { convertKitBroadcast } = data
  return (
    <>
      <Header />
      <TheLoopDetail broadcast={convertKitBroadcast} />
    </>
  )
}

export default TheLoopDetailPage

export const query = graphql`
  query ($id: String) {
    convertKitBroadcast(id: { eq: $id }) {
      id
      content
      publishedAt
    }
  }
`
